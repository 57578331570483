import React, { useEffect, useState } from "react";

const DateFormat = (props) => {
  const [date, setDate] = useState("");
  useEffect(() => {
    const cDate = new Date(props.date);
    let customDate = `${cDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${cDate.toLocaleString("default", {
        month: "short",
      })}-${cDate.getFullYear()}`;

    if (props.isTime) {
      customDate = `${customDate} ${cDate.getHours().toString()
        .padStart(2, "0")}:${cDate.getMinutes().toString()
          .padStart(2, "0")}`
    }
    setDate(customDate);
  }, [props.date]);

  return <div className={props.className}>{date}</div>;
};

export default DateFormat;
