import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../../services/user.service';
import { toastNotification } from "common/util_function";
import PForm from "components/Post/Form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const defaultData = {
  id: "",
  title: "",
  slug: "",
  thumbnailURL: "",
  content: "",
  categoryId: "",
  tags: ""
}

const PostForm = (props) => {
  const [categoryList, setCategoryList] = useState([]);
  const history = useHistory();
  const formData = { ...defaultData, ...props?.location?.data };

  useEffect(() => {
    getCategoryList();
    return () => { }
  }, []);

  const getCategoryList = () => {
    const url = `${URLs.category.list}?typeId=5`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setCategoryList(response.data.data);
        } else {
          setCategoryList([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Error in retrieving Logo List");
        setCategoryList([]);
      });
  };

  const onSubmit = async (fields, file) => {
    const userId = userService.userId();
    const formSubmitData = new FormData();
    formSubmitData.append("id", fields.id);
    formSubmitData.append("slug", fields.slug);
    formSubmitData.append("title", fields.title);
    formSubmitData.append("thumbnailURL", fields.thumbnailURL);
    formSubmitData.append("content", fields.content);
    formSubmitData.append("excerpt", fields.excerpt);
    formSubmitData.append("categoryId", fields.categoryId);
    formSubmitData.append("tags", fields.tags);
    formSubmitData.append("userId", userId);
    if (file) {
      formSubmitData.append("file", file, file.name)
    }
    const config = {
      method: "post",
      url: URLs.posts.form,
      data: formSubmitData,
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        history.push("/admin/posts");
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      toastNotification("error", error.message);
    }
  }

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Post Form</h6>
              <Link
                className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded"
                to="/admin/posts"
              >
                Back
              </Link>
            </div>
            <span className="text-xs italic">Please fill all the mandatory(<span className="text-red-500">*</span>) files </span>
          </div>
          <div className="w-full">
            <PForm formSubmit={onSubmit} formData={formData} categoryList={categoryList} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default PostForm;