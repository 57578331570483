import React, { useEffect, useState } from "react";
import axios from "axios";
import { URLs } from "constants/urls";
import { userService } from '../../services/user.service';
import { toastNotification, formattedDateTime } from "common/util_function";
import Modal from "components/UI/Modal";
import CampaignForm from "components/Campaigns/Form";
import CampaignList from "components/Campaigns/List";
import "react-datepicker/dist/react-datepicker.css";

const Campaigns = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [ecosystem, setEcosystem] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    total: 0,
  });
  const [campaignList, setCampaignList] = useState([]);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setMinutes(0);
  tomorrow.setHours(20);
  const defaultData = {
    id: 0,
    name: "",
    slug: "",
    description: "",
    otherFields: "",
    blockChains: [],
    categoryId: "",
    imageURL: "",
    thumbImageURL: "",
    adsImageURL: "",
    retweetURL: "",
    readMoreURL: "",
    endDate: tomorrow
  }
  const [formData, setFormData] = useState(defaultData);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCategoryList();
    getEcosystem();
    list(paginationConfig.limit, paginationConfig.page - 1);
    return () => { }
  }, []);

  const refreshPage = () => {
    list(paginationConfig.limit, ((paginationConfig.page - 1) * paginationConfig.limit));
  }

  const getCategoryList = () => {
    const url = `${URLs.category.list}?typeId=3`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setCategoryList(response.data.data);
        } else {
          setCategoryList([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Error in retrieving Logo List");
        setCategoryList([]);
      });
  };

  const getEcosystem = () => {
    const url = URLs.ecosystem.all;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          setEcosystem(response.data.data);
        } else {
          setEcosystem([]);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Error in retrieving Logo List");
        setEcosystem([]);
      });
  };

  const list = async (limit, offset) => {
    const config = {
      method: "post",
      url: URLs.campaign.adminList,
      data: { limit, offset },
    };
    setIsLoading(true);
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        setCampaignList(response.data.data);
        setPaginationConfig({
          ...paginationConfig,
          total: response.data.message,
        });
        setIsLoading(false);
      } else {
        setCampaignList([]);
        setIsLoading(false);
        throw new Error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      toastNotification("error", error.message);
      setCampaignList([]);
    }
  };

  const onClose = () => {
    document.body.classList.remove("overflow-hidden");
    setIsOpenForm(false);
    window.scrollTo(0, 0);
  };

  const onFetchRecordsHandler = (limit, page) => {
    list(limit, limit * (page - 1));
  };
  // moment.utc(fields.endDate).format("YYYY-MM-DD HH:mm:ss")
  const onSubmit = async (fields, images) => {
    const userId = userService.userId();
    const formSubmitData = new FormData();
    formSubmitData.append("id", fields.id);
    formSubmitData.append("blockChains", JSON.stringify(fields.blockChains));
    formSubmitData.append("categoryId", fields.categoryId);
    formSubmitData.append("description", fields.description);
    formSubmitData.append("endDate", formattedDateTime(fields.endDate));
    formSubmitData.append("name", fields.name);
    formSubmitData.append("otherFields", fields.otherFields);
    formSubmitData.append("readMoreURL", fields.readMoreURL);
    formSubmitData.append("imageURL", fields.imageURL);
    formSubmitData.append("thumbImageURL", fields.thumbImageURL);
    formSubmitData.append("adsImageURL", fields.adsImageURL);
    formSubmitData.append("retweetURL", fields.retweetURL);
    formSubmitData.append("slug", fields.slug);
    formSubmitData.append("userId", userId);
    const { bannerImageFile, thumbImageFile, adsImageFile } = images;
    if (bannerImageFile) {
      formSubmitData.append("bannerImageFile", bannerImageFile, bannerImageFile.name)
    }
    if (thumbImageFile) {
      formSubmitData.append("thumbImageFile", thumbImageFile, thumbImageFile.name)
    }
    if (adsImageFile) {
      formSubmitData.append("adsImageFile", adsImageFile, adsImageFile.name)
    }

    const config = {
      method: "post",
      url: URLs.campaign.form,
      data: formSubmitData,
    };
    try {
      const response = await axios(config);
      if (response.data.code === 200) {
        toastNotification("success", response.data.message);
        refreshPage();
        setTimeout(() => {
          onClose();
        }, 1000);
      } else {
        toastNotification("error", response.data.message);
      }
    } catch (error) {
      console.log(error);
      toastNotification("error", error.message);
    }
  }

  const openForm = (item) => {
    const blist = item.blockChainIds && item.blockChainIds.split(',');
    document.body.classList.add("overflow-hidden");
    window.scrollTo(0, 0);
    const data = {
      id: item.id,
      name: item.name,
      slug: item.slug,
      description: item.description,
      otherFields: item.otherFields,
      blockChains: item.ecosystems,
      categoryId: item.categoryId,
      retweetURL: item.retweetURL,
      imageURL: item.imageURL,
      thumbImageURL: item.thumbImageURL,
      adsImageURL: item.adsImageURL,
      readMoreURL: item.readMoreURL,
      endDate: new Date(item.endDate)
    }
    setFormData(data);
    setIsOpenForm(true)
  };

  const onDeleteHandler = (item) => {
    const url = `${URLs.campaign.delete}?id=${item.id}&active=${item.active}`;
    axios
      .get(url)
      .then((response) => {
        if (response.data.code === 200) {
          toastNotification("success", response.data.message);
          refreshPage();
        } else {
          toastNotification("error", response.data.message);
          throw new Error(response.data.message);
        }
      })
      .catch((err) => {
        toastNotification("error", "Failed to delete the campaing");
      });
  };

  return (
    <div className="flex flex-wrap">
      <div className="w-full lg:w-12/12 mt-20 px-4">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-gray-800 text-xl font-bold uppercase">Campaings List</h6>
              <button type="submit" onClick={() => openForm(defaultData)} className="bg-blue-800 text-white focus:outline-none font-bold py-2 px-4 rounded">Add New </button>
            </div>
          </div>
          <div className="w-full">
            <CampaignList
              campaignList={campaignList}
              ecosystems={ecosystem}
              paginationConfig={paginationConfig}
              onFetchRecords={onFetchRecordsHandler}
              onEdit={openForm}
              onDelete={onDeleteHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>

      {isOpenForm && (
        <Modal onClose={onClose} className="cb-large">
          <div className="flex justify-between items-center p-4 bg-blue-600">
            <h6 className="text-white text-xl font-bold uppercase">Campaign Form</h6>
            <button onClick={onClose} className="cursor-pointer border-2 h-6 w-6 flex items-center focus:outline-none justify-center rounded-xl"><i className="fas fa-times text-white"></i></button>
          </div>
          <div className="px-8 py-4">
            <CampaignForm formSubmit={onSubmit} formData={formData} categoryList={categoryList} ecosystems={ecosystem} />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Campaigns;